import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faWifi } from '@fortawesome/free-solid-svg-icons'

import api from '../utils/api'

const Broadband = ({ id, channel, isVisible, ...props }) => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState()
    
    useEffect(() => {
        if (!data && !loading && isVisible) {
            setLoading(true)
            api(`/property/${id}/broadband?channel=${channel}`).then(d => {
                setData(d)
                setLoading(false)
            })
        }
    }, [isVisible, id, channel, data, loading])

    return (
        <li {...props}>
            <FontAwesomeIcon icon={faWifi} />
            &nbsp;
            {data && (
                `${data.speed_display} from ${data.provider_name}`
            )}
        </li>
    )
}

export default styled(Broadband)``