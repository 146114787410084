import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'

import api from '../utils/api'

const Broadband = ({ lat, lng, isVisible, ...props }) => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState()
    
    useEffect(() => {
        if (!data && !loading && isVisible) {
            setLoading(true)
            api(`/Deliveroo/GetDeliveryRestaurants/${lat}+${lng}`).then(d => {
                setData(d)
                setLoading(false)
            })
        }
    }, [isVisible, lat, lng, data, loading])

    return (
        <li {...props}>
            <FontAwesomeIcon icon={faUtensils} />
            &nbsp;
            {data && (
                `${data} Restaurants on Deliveroo!`
            )}
        </li>
    )
}

export default styled(Broadband)``