import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faStar, faTrain, faMapMarker,faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import SimpleReactLightbox, { SRLWrapper, useLightbox } from "simple-react-lightbox"
import { useInView } from 'react-hook-inview'
import api from '../utils/api'

import Button from './Button'
import Map from './Map'

import Broadband from './Broadband'
import Deliveroo from './Deliveroo'
import Toggle from './Toggle'

const Footer = styled.div`
    border-top: 2px solid #f7f7f7;
    padding: 18px 12px;
    font-size: 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Price = styled.h1`
    color: ${({ theme: { brandColor }}) => brandColor};
    font-weight: bold;
    font-size: 24px;
`
const PriceHeading = styled.p`
    color: ${({ theme: { brandColor }}) => brandColor};
    font-weight: bold;
`

const Amenities = styled.ul`
    font-size: 14px;
    line-height: 1.3em;
    svg {
        margin-right: 8px;
    }
`

const Address = styled.p`
    font-size: 12px;
`

const CircleIcon = styled.div`
    background: #4f4f58;
    color: #fff;
    display: inline-block;
    border-radius: 100%;
    font-size: 10px;
    padding: 6px;
`


const TravelInfoLine = styled.li`
    display: flex;
    align-items: center;
    ${CircleIcon} {
        margin-right: 6px;
    }
`

const TravelInfo = styled.ul`
    font-size: 13px;
    ${TravelInfoLine}:not(:last-child) {
        margin-bottom: 4px;
    }    
`

const Tags = styled.div`
`

export const Tag = styled.div`
    background: #f7f7f7;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 11px;
    padding: 4px 6px;
    border-radius: 2px;
    display: inline-block;
    border: 0.5px solid #d7d7eb;
`

const Image = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    ${({ src }) => css`
        background-image: url('${src}');
    `}
`

export const AgencyLogo = styled(Image)`
    width: 90px;
    height: 53px;
    background-size: contain;
    background-position: top right;
`

const Details = styled.div`
    color: #4f4f58;
    flex: 1;
    padding: 12px;
    padding-left: 18px;

    ${PriceHeading} {
        margin-bottom: 4px;
    }

    ${Price} {
        margin-top: 6px;
        margin-bottom: 4px;
    }

    ${Address} {
        margin-top: 4px;
    }

    ${Amenities} {
        margin-top: 14px;
        margin-bottom: 16px;
    }

    ${TravelInfo} {
        margin-top: 21px;
        padding-bottom: 4px;
    }

    ${Tags} {
        margin-top: 12px;
    }
`

const Body = styled.div`
    display: flex;
    justify-content: space-between;

    ${AgencyLogo} {
        margin: 12px;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`

const Container = styled.div`
    background: #fff;
    flex: 1;
    max-width: 100%;

    ${Footer} {
        margin-top: 12px;
    }
`

const SuperContainer = styled.li`
    display: flex;
    border: 0.5px solid #d7d7eb;
    border-radius: 2px;

    @media (max-width: 1024px) {
        > ${Map} {
            display: none;
        }
    }
`

const noShouting = text => text ? text.split(' ').map(string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()).join(' ') : ''

const CarouselContainer = styled.div`
    width: 354px;
    @media (max-width: 1024px) {
        width: calc(100% - 24px) !important;
    }
    img {
        max-height: 300px;
    }
    .thumb img {
        max-height: 58px;
    }
    padding: 12px;

    .carousel:not(.carousel-slider) .control-prev.control-arrow:before {
        border-right: 8px solid ${({ theme: { brandColor } }) => brandColor};
    }
    .carousel:not(.carousel-slider) .control-next.control-arrow:before {
        border-left: 8px solid ${({ theme: { brandColor } }) => brandColor};
    }
    .carousel .thumb.selected, .carousel .thumb:hover {
        border: 1px solid ${({ theme: { brandColor } }) => brandColor};
    }
`

const Images = ({ photoLargeThumbnailUrl, photos }) => {
    const { openLightbox } = useLightbox()

    return (
        <SRLWrapper>
            <Carousel showArrows={true} width="100%" dynamicHeight onClickItem={(idx) => {
                openLightbox(idx)
            }}>
                {photos ? photos.map(({ identifier, url }) => (
                    <div key={identifier}><img alt="" src={url} /></div>
                )) : (
                    <div><img alt="" src={photoLargeThumbnailUrl} /></div>
                )}
            </Carousel>
        </SRLWrapper>
    )
}

const makeLink = (type, uri) => {
    switch (type) {
        case 'youtube':
            return 'https://www.youtube.com/watch?v='+uri;
        case 'vimeo':
            return 'https://vimeo.com/'+uri;
        default: // other
            return uri;
    }
}

const VirtualTourList = styled.ul`
    a {
        color: ${({ theme: { brandColor }}) => brandColor};
        text-decoration: none;
    }
`

const VirtualTours = ({ tours }) => {
    return (
        <VirtualTourList>
            {tours.map(({ type, uri }) => (
                <li key={uri}>
                    <a target="_blank" rel="noopener noreferrer" href={makeLink(type, uri)}>
                        <FontAwesomeIcon icon={faPlayCircle} />&nbsp;
                        Watch Virtual Tour
                    </a>
                </li>
            ))}
        </VirtualTourList>
    )
}

function millisecondsToStr(milliseconds) {
    // TIP: to find current time in milliseconds, use:
    // var  current_time_milliseconds = new Date().getTime();

    function numberEnding (number) {
        return (number > 1) ? 's' : '';
    }

    var temp = Math.floor(milliseconds / 1000);
    var years = Math.floor(temp / 31536000);
    if (years) {
        return years + ' year' + numberEnding(years);
    }
    //TODO: Months! Maybe weeks? 
    var days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
        return days + ' day' + numberEnding(days);
    }
    var hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
        return hours + ' hour' + numberEnding(hours);
    }
    var minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
        return minutes + ' minute' + numberEnding(minutes);
    }
    var seconds = temp % 60;
    if (seconds) {
        return seconds + ' second' + numberEnding(seconds);
    }
    return 'less than a second'; //'just now' //or other string you like;
}

const MobileToggle = styled(Toggle)`
    @media (min-width: 900px) {
        display: none;
    }
`

const Property = ({
    photoLargeThumbnailUrl = 'https://via.placeholder.com/354x256',
    bedrooms = 1,
    numBaths = 1,
    numLiving = 1,
    latitude,
    longitude,
    propertyType,
    channel,
    address = 'Mansford Street, London E2',
    travel = [
        { type: 'train', distance: '0.3 miles', name: 'Cambridge Heath' },
        { type: 'subway', distance: '0.4 miles', name: 'Bethnal Green' },
    ],
    expired = false,
    monthlyRent = 420000,
    price,
    currencySymbol = '£',
    className,
    updateDate,
    travelTime,
    ...props
}) => {
    const [ref, isVisible] = useInView({
        threshold: 0.1,
    })

    const [value, setValue] = useState()
    const [loading, setLoading] = useState()
    const [mobileView, setMobileView] = useState('Details')

    useEffect(() => {
        if (isVisible && !loading && !value) {
            setLoading(true)
            api(`/property/${props.identifier}`).then(d => {
                setValue(d)
                setLoading(false)
            })
        }
    }, [loading, value, isVisible, props.identifier])

    return (
        <SimpleReactLightbox>
            <SuperContainer className={className}>
                <Container ref={ref}>
                    <Body>
                        <CarouselContainer>
                            <Images photoLargeThumbnailUrl={photoLargeThumbnailUrl} photos={value && value.photos} />
                        </CarouselContainer>
                        <Details>
                            <PriceHeading>{bedrooms && `${bedrooms} bedroom `}{propertyType}</PriceHeading>
                            <Price>{currencySymbol}{Number(monthlyRent || price).toLocaleString()} {monthlyRent ? 'pcm' : ''}</Price>
                            <Address>{address}</Address>
                            
                            <MobileToggle
                                name="mobileView"
                                value={mobileView}
                                setValue={setMobileView}
                                options={[
                                    { label: "Details", value: "Details", default: true },
                                    { label: "Map", value: "Map" },
                                ]}
                            />
                            {mobileView === 'Map' ? (
                                <Map lat={latitude} lng={longitude} />
                            ) : (
                                <>
                                    <TravelInfo>
                                        {travelTime ? (
                                            <TravelInfoLine>
                                                <CircleIcon>
                                                    <FontAwesomeIcon icon={faMapMarker} />
                                                </CircleIcon>
                                                {millisecondsToStr(travelTime * 1000)} Travel Time
                                            </TravelInfoLine>
                                        ) : null}
                                        {value && value.stations.map(({ type, distance, station }) => (
                                            <TravelInfoLine key={station}>
                                                <CircleIcon>
                                                    <FontAwesomeIcon icon={faTrain} />
                                                </CircleIcon>
                                                {distance} miles to {station}
                                            </TravelInfoLine>
                                        ))}
                                    </TravelInfo>
                                    <Amenities>
                                        <Broadband channel={channel} id={props.identifier} isVisible={isVisible} />
                                        <Deliveroo lat={latitude} lng={longitude} isVisible={isVisible} />
                                        {value && value.features.map(({ identifier, featureDescription }) => (
                                            <li key={identifier}>
                                                <FontAwesomeIcon icon={faStar} />
                                                &nbsp;{noShouting(featureDescription)}
                                            </li>
                                        ))}
                                    </Amenities>
                                    {value && !!value.virtualTours.length && <VirtualTours tours={value.virtualTours} />}
                                </>
                            )}
                        </Details>
                    </Body>
                    <Footer>
                        <span>Last updated: {moment(updateDate).format('DD/MM/YYYY')}</span>
                        <span>Date Available: {value && (value.letDateAvailable || 'unknown')}</span>
                        <Button href={value && value.publicsiteUrl} rel="noopener noreferrer" disabled={!value} target="_blank"> 
                            View on Rightmove
                        </Button>
                    </Footer>
                </Container>
                <Map lat={latitude} lng={longitude} />
            </SuperContainer>
        </SimpleReactLightbox>
    )
}

export default styled(Property)``