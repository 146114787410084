import React, { useRef, useState, useEffect } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components/macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'

import Autocomplete from "./Autocomplete"
import api from '../utils/api'
import useLocationId from '../hooks/useLocationId'

const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-right: 8px;
    background: white;
    border: 1px solid #dfe1e5;
    
    input {
        z-index: 999;
        flex: 1;
        background: transparent !important;
    }

    .ac-container {
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        background: transparent !important;
    }

    .autocomplete {
        width: 100%;
    }

    .autocomplete-list {
        width: calc(100% + 58px);
        left: -51px;
        padding-bottom: 6px;
        border-color: #dfe1e5;
        border-radius: 24px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: -3px;

        /* width */
        ::-webkit-scrollbar {
            width: 10px;
            margin-right: -10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
            opacity: 0.6;
            border-radius: 8px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
            opacity: 1;
        }

        .autocomplete-li {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 2.5em;
        }
    }
`

export const IconContainer = styled.div`
    display: inline-block;
    padding-left: 18px;
    padding-right: 12px;
    color: #9aa0a6;
    font-size: 20px;
    z-index: 999;
    height: 100%;
    background: #fff;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
`

const Overlay = styled.div`
    width: 100%;
`

const GlobalStyle = createGlobalStyle`
    .ac-open {
        ${Overlay} {
            @media (max-width: 700px) {
                position: fixed;
                top: 42px;
                background: white;
                width: 100%;
                height: calc(100vh - 32px);
                background: rgba(0,0,0,0.4);

                ${Container} {
                    margin-top: 0 !important;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border: none;
                }

                .autocomplete-list {
                    max-width: unset;
                    border: none;
                }
            }
            
        }
        .autocomplete-list {
            
            box-shadow: 0 4px 6px 0 rgba(32,33,36,0.28);
        }
        ${Container} {
            border-bottom: none;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0!important;
            box-shadow: 0 4px 6px 0 rgba(32,33,36,0.28);
        }
    }
`

const LocationSearch = ({ onChange, className }) => {
    const ref = useRef()
    const [value, setValue] = useState()
    const { name } = useLocationId()

    useEffect(() => {
        if (value) {
            api(`/property/searchidentifier/${value}`)
                .then(res => ref.current.setItems(res))
        } else {
            ref.current.setItems([])
        }
    }, [value])

    return (
        <Overlay hasValue={!!value}>
            <GlobalStyle />
            <Container {...{className}} hasValue={!!value}>
                <IconContainer>
                    <FontAwesomeIcon icon={faSearchLocation} />
                </IconContainer>
                <Autocomplete
                    ref={r => ref.current = r}
                    value={name}
                    renderItem={({ item: { name }}) => name}
                    onChange={setValue}
                    onSelect={onChange}
                />
            </Container>
        </Overlay>
    )
}

export default styled(LocationSearch)`
    box-shadow: none;
    border-radius: 24px;
    width: 100%;

    input {
        border: none;
        border-radius: 24px;
        font-size: 1.4em;
        line-height: 1.8em;

        :focus {
            border: none;
            outline: none;
        }
    }

    ${({ small }) => small && css`
        border-radius: 12px;
        font-size: 14px;
        .loading {
            margin: 0;
            display: none;
        }
        .autocomplete-list {
            font-size: 14px;
            width: calc(100% - -38px);
            left: -31px;
        }
        ${IconContainer} {
            padding-left: 8px;
            font-size: 1em;
            padding-right: 8px;
        }
        input {
            border: none;
            border-radius: 12px;
            font-size: 1em;
            line-height: 1.2em;
        }
    `}
`