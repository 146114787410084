import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import { ThemeProvider } from 'styled-components/macro'

import './main.scss'

import Home from './routes/Home'
import PropertyList from './routes/PropertyList'

const App = () => (
  <ThemeProvider theme={{
    brandColor: '#621680',
    inputBorderColor: '',
  }}>
      <Router>
        <Routes>
          <Route path="/location/:name/:locationId" element={<PropertyList/>} />
          <Route path="/" element={<Home/>} />
        </Routes>
      </Router>
  </ThemeProvider>
)

export default App;
