import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import NavBar from '../components/NavBar'
import { Grid } from '../components/Grid'

import Property from '../components/Property'
import FilterDrawer from '../components/FilterDrawer'
import EndOfList from '../components/EndOfList'

import useProperties from '../hooks/useProperties'
import useFilters from '../hooks/useFilters'

const ListContainer = styled.ul`
    width: 100%;
    list-style-type: none;

    ${Property}:not(:last-child) {
        margin-bottom: 24px;
    }

    ${EndOfList} {
        margin-top: 24px;
    }
`

const PropertyCount = styled.p`
    padding-top: 8px;
    padding-bottom: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
`

const Container = styled.div`
    padding-top: 45px;
`

const PropertyList = () => {
    const { locationId } = useParams()
    const { filters, setFilters } = useFilters()
    const { loading, error, properties, propertyCount } = useProperties(locationId, filters)
    const [isExpanded, setIsExpanded] = useState(true)
    const numPages = propertyCount ? Math.ceil(propertyCount / filters.numberOfPropertiesPerPage) : 0

    return (
        <Container>
            <NavBar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            <FilterDrawer isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            <Grid>
                <PropertyCount>
                    <span>{propertyCount ? `Found ${propertyCount} properties` : ''}</span>
                    <span> Page {filters.page || 1} of {numPages || '...'}</span>
                </PropertyCount>
                <ListContainer style={{
                    opacity: loading ? 0.6 : 1,
                }}>
                    {error && 'Error occured loading properties'}
                    {properties && properties.map(property => (
                        <Property
                            key={property.identifier}
                            channel={filters.channel}
                            {...property}
                        />
                    ))}
                    {properties && 
                        <EndOfList 
                            loading={loading}
                            isEnd={propertyCount === properties.length}
                            currentPage={parseInt(filters.page, 10)}
                            numPages={numPages}
                            goToPage={(page) => {
                                setFilters({
                                    ...filters,
                                    page,
                                })
                                window.scrollTo(0,0)
                            }}
                        />
                    }
                </ListContainer>
            </Grid>
        </Container>
    )
}

export default PropertyList