import styled from 'styled-components/macro'

export const Grid = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;

    @media (max-width: 1024px) {
        padding-left: 12px;
        padding-right: 12px;
    }
`
