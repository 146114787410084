import React from 'react'
import styled, { css } from 'styled-components/macro'
import { transparentize, darken } from 'polished'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faMapMarker, faLightbulb } from '@fortawesome/free-solid-svg-icons'

import LocationSearch, { IconContainer } from './LocationSearch'
import { Grid } from './Grid'
import Button from './Button'
import Toggle from './Toggle'

import useFilters from '../hooks/useFilters'
import useLocationId from '../hooks/useLocationId'

const HintLink = styled.a`
    text-decoration: none;
    color: black;
    opacity: 0.7;
    font-size: 13px;
    margin-top: 6px;

    span {
        margin-left: 8px;
    }
`

const SubmitButton = styled(Button)`
    width: 225px;
    align-self: flex-end;
`

const CInputContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: space-between;

    span {
        color: grey;
        background: lightgray;
        height: 100%;
        box-sizing: border-box;
        padding-top: 5px;
        padding-left: 4px;
        padding-right: 4px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        font-size: 0.8em;
    }

    input {
        border-left: none;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    select {
        width: 100%;
    }
`

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;

    input.autocomplete-input {
        border: none;
    }

    input, select {
        border-color: ${darken(0.05)('#dfe1e5')};
        border-width: 1px;
        border-style: solid;
        width: 100%;
        padding: 4px;
        border-radius: 8px;
    }

    > label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
    }
`

const Container = styled.div`
    border-bottom: 2px solid #ebebeb;
    z-index: -1;
    transition: 300ms margin-top;
    background: white;

    margin-top: 0px;

    ${({ isExpanded }) => !isExpanded && css`
         margin-top: -342px;
    `}

    .ac-container, ${IconContainer} {
        z-index: 0;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    
    @media (max-width: 1024px) {
        flex-direction: column;
    }

    ${FormGroup} {
        flex: 1;
        padding: 12px;
    }

    ${FormGroup} ${FormGroup} {
        padding: 20px 0;
    }

     > div:first-child {
        padding-left: 0;
    }

    > div:last-child {
        padding-right: 0;
    }

    ${CInputContainer}:not(:first-child) {
        padding-left: 12px;
    }
`

const CurrencyRow = styled(Row)`
    @media (max-width: 1024px) {
        flex-direction: row;
    }
`

const HeaderRow = styled(Row)`
    flex-direction: column;
    align-items: center;
    margin-right: 32px;

    @media (max-width: 1024px) {
        margin-right: 0;
    }

    > div {
        padding-left: 0;
    }
`

const FilterForm = styled.form`
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;
`

const OtherContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`

const CheckboxContainer = styled.div`
    position: relative;
    display: inline-block;
    align-items: center;
    color: ${({ theme: { brandColor }}) => transparentize(0.1)(brandColor)};
    font-size: 0.9em;
    margin-top: 20px;

    label {
        padding: 8px;
        border-radius: 6px;
        padding-left: 20px;
        border: 1px solid ${({ theme: { brandColor }}) => transparentize(0.3)(brandColor)};
    }

    :not(:last-child) {
        margin-bottom: 8px;
    }

    input {
        width: 100%;
        position: absolute;
        height: 100%;
        border: none;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        appearance: none;
        cursor: pointer;
    }

    input:after {
        box-sizing: border-box;
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        z-index: -1;
        pointer-events: none;
    }

    input:checked:before {
        content: '✓';
        font-weight: bold;
        color: white;
        position: absolute;
        top: -1.5px;
        left: 6px;
    }

    input:checked + label {
        color: white;
        background: ${({ theme: { brandColor }}) => brandColor};
    }
`

const Checkbox = ({ label, ...checkboxProps }) => (
    <CheckboxContainer>
        <input type="checkbox" {...checkboxProps} />
        <label htmlFor={checkboxProps.name}>
            {label}
        </label>
    </CheckboxContainer>
)

const Checkboxes = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
`

const CurrencyInput = (inputProps) => (
    <CInputContainer>
        <span>£</span>
        <input {...inputProps} />
    </CInputContainer>
)

const LatLngContainer = styled.div`
    display: flex;
    align-items: flex-end;
    max-width: 220px;
`

const FAMarkerContainer = styled.div`
    display: inline-flex;
    height: 100%;
    align-items: center;

    margin-right: 4px;
    opacity: 0.7;
`

const FilterDrawer = ({ isExpanded, setIsExpanded }) => {
    const { filters, setFilters } = useFilters()
    const { setLocationId } = useLocationId()

    return (
        <Container isExpanded={isExpanded}>
            <Grid>
                <FilterForm onSubmit={(e) => {
                    e.preventDefault();
                    const obj = {
                        channel: [...e.target.channel].map(ele => ele.checked ? ele.id.split('channel').join('') : undefined).filter(Boolean)[0],
                        radius: e.target.radius.value,
                        timeToLat: e.target.timeToLat.value,
                        timeToLng: e.target.timeToLng.value,
                        minPrice: e.target.minPrice.value,
                        maxPrice: e.target.maxPrice.value,
                        minBedrooms: e.target.minBedrooms.value,
                        maxBedrooms: e.target.maxBedrooms.value,
                        maxDaysSinceAdded: e.target.maxDaysSinceAdded.value,
                        sortBy: e.target.sortBy.value,
                        furnishTypes: [...e.target.furnishTypes].map(ele => ele.checked ? ele.value : undefined).filter(Boolean).join(','),
                        propertyTypes: [...e.target.propertyTypes].map(ele => ele.checked ? ele.value : undefined).filter(Boolean).join(','),
                    }
                    setFilters(obj)
                    setIsExpanded(true)
                } }>
                    <SubContainer>
                        <HeaderRow>
                            <FormGroup>
                                <label>Search Area</label>
                                <LocationSearch small onChange={setLocationId} />
                            </FormGroup>
                            <FormGroup>
                                <label>Search Type</label>
                                <div>
                                    <Toggle
                                        name="channel"
                                        options={[
                                            { label: "Rent", value: "rent", default: filters.channel === 'rent' },
                                            { label: "Buy", value: "buy", default: filters.channel === 'buy' },
                                        ]}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>Search Radius</label>
                                <select name="radius" defaultValue={filters.radius}>
                                    <option value="0.25">+0.25 Miles</option>
                                    <option value="0.5">+0.5 Miles</option>
                                    <option value="1">+1 Miles</option>
                                    <option value="3">+3 Miles</option>
                                    <option value="5">+5 Miles</option>
                                    <option value="10">+10 Miles</option>
                                    <option value="15">+15 Miles</option>
                                    <option value="20">+20 Miles</option>
                                    <option value="30">+30 Miles</option>
                                    <option value="40">+40 Miles</option>
                                </select>
                            </FormGroup>
                        </HeaderRow>
                        <OtherContainer>
                            <Row>
                                <FormGroup>
                                    <label>Price Range (£ pcm)</label>
                                    <CurrencyRow>
                                        <CurrencyInput placeholder="No Min" type="text" maxLength="9" name="minPrice" defaultValue={filters.minPrice} />
                                        <CurrencyInput placeholder="No Max" type="text" maxLength="9" name="maxPrice" defaultValue={filters.maxPrice} />
                                    </CurrencyRow>
                                </FormGroup>

                                <FormGroup>
                                    <label>Bedrooms</label>
                                    <CurrencyRow>
                                        <CInputContainer>
                                            <select name="minBedrooms" defaultValue={filters.minBedrooms}>
                                                <option value="">No Min</option>
                                                <option value="0">studio</option>
                                                <option value="1">1 Bedroom</option>
                                                <option value="2">2 Bedroom</option>
                                                <option value="3">3 Bedroom</option>
                                                <option value="4">4 Bedroom</option>
                                                <option value="5">5 Bedroom</option>
                                                <option value="6">6 Bedroom</option>
                                                <option value="7">7 Bedroom</option>
                                                <option value="8">8 Bedroom</option>
                                                <option value="9">9 Bedroom</option>
                                                <option value="10">10 Bedroom</option>
                                            </select>
                                        </CInputContainer>
                                        <CInputContainer>
                                            <select name="maxBedrooms" defaultValue={filters.maxBedrooms}>
                                                <option value="">No Max</option>
                                                <option value="0">studio</option>
                                                <option value="1">1 Bedroom</option>
                                                <option value="2">2 Bedroom</option>
                                                <option value="3">3 Bedroom</option>
                                                <option value="4">4 Bedroom</option>
                                                <option value="5">5 Bedroom</option>
                                                <option value="6">6 Bedroom</option>
                                                <option value="7">7 Bedroom</option>
                                                <option value="8">8 Bedroom</option>
                                                <option value="9">9 Bedroom</option>
                                                <option value="10">10 Bedroom</option>
                                            </select>
                                        </CInputContainer>
                                    </CurrencyRow>
                                </FormGroup>

                                <FormGroup>
                                    <label>Date Added</label>

                                    <select name="maxDaysSinceAdded" defaultValue={filters.maxDaysSinceAdded}>
                                        <option value="">Anytime</option>
                                        <option value="1">Last 24 Hours</option>
                                        <option value="3">Last 3 Days</option>
                                        <option value="7">Last 7 Days</option>
                                        <option value="14">Last 14 Days</option>
                                    </select>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup>
                                    <label>Furnish Type</label>
                                    <Checkboxes>
                                        <Checkbox label="Furnished" value="furnished" name="furnishTypes" defaultChecked={filters.furnishTypes.split(',').includes('furnished')}/>
                                        <Checkbox label="Unfurnished" value="unfurnished" name="furnishTypes" defaultChecked={filters.furnishTypes.split(',').includes('unfurnished')}/>
                                        <Checkbox label="Part Furnished" value="partfurnished" name="furnishTypes" defaultChecked={filters.furnishTypes.split(',').includes('partfurnished')}/>
                                    </Checkboxes>
                                </FormGroup>

                                <FormGroup>
                                    <label>Property Type</label>
                                    <Checkboxes>
                                        <Checkbox label="Detached" value="detached" name="propertyTypes" defaultChecked={filters.propertyTypes.split(',').includes('detached')}/>
                                        <Checkbox label="Semidetached" type="checkbox" value="semidetached" name="propertyTypes" defaultChecked={filters.propertyTypes.split(',').includes('semidetached')}/>
                                        <Checkbox label="Terraced" type="checkbox" value="terraced" name="propertyTypes" defaultChecked={filters.propertyTypes.split(',').includes('terraced')}/>
                                        <Checkbox label="Flat" type="checkbox" value="flat" name="propertyTypes" defaultChecked={filters.propertyTypes.split(',').includes('flat')}/>
                                        <Checkbox label="Bungalow" type="checkbox" value="bungalow" name="propertyTypes" defaultChecked={filters.propertyTypes.split(',').includes('bungalow')}/>
                                        <Checkbox label="Land" type="checkbox" value="land" name="propertyTypes" defaultChecked={filters.propertyTypes.split(',').includes('land')}/>
                                        <Checkbox label="Park Home" type="checkbox" value="parkhome" name="propertyTypes" defaultChecked={filters.propertyTypes.split(',').includes('parkhome')}/>
                                    </Checkboxes>
                                </FormGroup>

                                <FormGroup>
                                    <label>Sort By</label>
                                    <select name="sortBy" defaultValue={filters.sortBy}>
                                        <option value="distance">Distance</option>
                                        <option value="lowestPrice">Lowest Price</option>
                                        <option value="highestPrice">Highest Price</option>
                                        <option value="newestListed">Newest Listed</option>
                                        <option value="oldestListed">Oldest Listed</option>
                                    </select>
                                    <FormGroup>
                                        <label>
                                            Show travel time to
                                        </label>
                                        <LatLngContainer>
                                            <FAMarkerContainer>
                                                <FontAwesomeIcon icon={faMapMarker} />
                                            </FAMarkerContainer>
                                            <input name="timeToLat" placeholder="latitude" defaultValue={filters.timeToLat} />,&nbsp;
                                            <input name="timeToLng" placeholder="longitude" defaultValue={filters.timeToLng} />
                                        </LatLngContainer>
                                        <HintLink href="https://www.clubrunnersupport.com/article/1416-how-to-find-a-location-s-latitude-longitude-in-google-maps" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faLightbulb} />
                                            <span>How to find coordinates with Google Maps</span>
                                        </HintLink>
                                    </FormGroup>
                                </FormGroup>
                            </Row>
                            
                        </OtherContainer>
                    </SubContainer>

                    <SubmitButton>Submit</SubmitButton>
                </FilterForm>
            </Grid>
        </Container>
    );
}

export default FilterDrawer;
