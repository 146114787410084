import React from 'react'
import styled from 'styled-components/macro'

const apiKey = 'AIzaSyCz0GhHrrFY8ArPMhkqZYH1lKfi7mfa5FI'

const Map = ({ lat, lng, className }) => (
    <iframe
        className={className}
        title={`map centered at ${lat},${lng}`}
        frameBorder="0" style={{ border:0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${lat},${lng}`} allowFullScreen>
    </iframe>
)

export default styled(Map)``