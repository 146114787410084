import styled from 'styled-components/macro'

const Button = styled.a.attrs(({ href }) => ({
    as: href ? 'a' : 'button',
}))`
    background: ${({ theme: { brandColor }}) => brandColor};
    color: white;
    border: none;
    padding: 8px 18px;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;

    :focus {
        outline-color: black;
    }
`

export default Button