import { useCallback } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

const useLocationId = () => {
    const { location, name } = useParams()
    const history = useNavigate()
    const qryParams = useLocation().search

    const setLocationId = useCallback(({ name, identifier }) => {
        history(`/location/${name}/${identifier}?${qryParams}`)
        //history(0)
    }, [history, qryParams])

    return {
        setLocationId,
        location,
        name,
    }
}

export default useLocationId