import React from 'react'
import styled, { css } from 'styled-components/macro'

import Button from './Button'

const PageBox = styled.div`
    border: 0.4px solid ${({ theme: { brandColor }}) => brandColor};
    color: ${({ theme: { brandColor }}) => brandColor};
    padding: 4px;
    cursor: pointer;
    opacity: 0.8;

    ${({ current }) => current && css`
        background: ${({ theme: { brandColor }}) => brandColor};
        color: white;
    `}

    :hover {
        background: ${({ theme: { brandColor }}) => brandColor};
        color: white;
    }
`

const Pages = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${PageBox}:not(:first-child) {
        margin-left: 4px;
    }
`

const ButtonContainer = styled.div`
    text-align: center;
    margin-bottom: 21px;

    ${Pages} {
        margin-top: 12px;
    }
`


const EndOfList = ({ className, currentPage, numPages, loading, isEnd, goToPage }) => (
    <ButtonContainer className={className}>
        <Button disabled={loading || isEnd} onClick={() => goToPage(currentPage + 1)}>
            {loading && 'Loading...'}
            {isEnd && 'End of list'}
            {!loading && !isEnd && 'Next Page'}
        </Button>

        <Pages>
            {Array.from(Array(numPages), (_, i) => i + 1).map(i => (
                <PageBox key={i} current={i === currentPage} onClick={() => goToPage(i)}>
                    {i}
                </PageBox>
            ))}
        </Pages>
    </ButtonContainer>
)

export default styled(EndOfList)``