import React from 'react'

import styled from 'styled-components/macro'
import Typist from 'react-typist'
import 'react-typist/dist/Typist.css'

import NavBar from '../components/NavBar'
import {Grid} from '../components/Grid'
import LocationSearch from '../components/LocationSearch'
import useLocationId from '../hooks/useLocationId'

const Search = styled(LocationSearch)`
    
`

const HomeContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    padding-top: 100px;
    max-width: 584px;
    margin: auto;

    ${Search} {
        margin-top: 28px;
    }

    h1 {
        font-size: 2.5em;
        margin-bottom: 36px;
    }

    h2 {
        font-size: 1em;
    }
`

const Home = () => {
    const { setLocationId } = useLocationId()

    return (
        <>
            <NavBar />
            <Grid>
                <HomeContainer>
                    <h1>
                        <Typist cursor={{hideWhenDone: true, hideWhenDoneDelay: 0, blink: true}}>
                            <span>Worngm</span>
                            <Typist.Backspace count={5} delay={200}/>
                            <span>rongMoev</span>
                            <Typist.Backspace count={2} delay={100}/>
                            <span>ve</span>
                        </Typist>
                    </h1>
                    <h2>Your next home, without the headache.</h2>
                    <Search onChange={setLocationId} />
                </HomeContainer>
            </Grid>
        </>
    )
}

export default Home