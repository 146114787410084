import { useState, useEffect } from 'react'

import api from '../utils/api'

const clean = a => {
    const obj = {...a}
    Object.keys(obj).forEach(key => {
        if (obj[key] === undefined || obj[key] === '') {
          delete obj[key]
        }
    })
    return obj
}

const cache = {}

const useProperties = (locationId, filters) => {
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)
    const [properties, setProperties] = useState(null)
    const [propertyCount, setPropertyCount] = useState(null)

    const qs = new URLSearchParams(clean(filters)).toString()

    useEffect(() => {
        if (cache[qs]) {
            const { properties, totalAvailableResults } = cache[qs]
            setProperties(properties)
            setPropertyCount(totalAvailableResults)
            return
        }
        setLoading(true)
        setError(false)
        api(`/property/location/${locationId}/?${qs}`).then(a => {
            if (a.errors) {
                throw a
            }
            cache[qs] = a
            setProperties(a.properties)
            setPropertyCount(a.totalAvailableResults)
            setLoading(false)
        }).catch(e => {
            setLoading(false)
            setError(e)
        })
    }, [locationId, setLoading, qs])

    return {
        loading,
        error,
        properties,
        propertyCount,
    }
}

export default useProperties