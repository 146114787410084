import styled from 'styled-components/macro'
import { SegmentedControl } from 'segmented-control'

const Toggle = styled(SegmentedControl).attrs(() => ({ style: { width: 200 } }))`
    width: 200px;
    height: 1.8em;
    color: ${({ theme: { brandColor }}) => brandColor};
    transition-duration: 0.3s;
    margin-bottom: 0;
    margin-top: 0;
    border: none;
    border: 1px solid purple;
    label {
        line-height: 1.55em;
    }
`

export default Toggle