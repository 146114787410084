import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseUser, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { transparentize } from 'polished'

import { Grid } from './Grid'

const Container = styled.div`
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    height: 42px;
    display: flex;
    z-index: 999;
    position: fixed;
    width: 100%;
    top: 0;
`

const NavGrid = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Branding = styled(Link).attrs(() => ({ to: '/' }))`
    font-weight: bold;
    opacity: 0.8;
    text-decoration: none;
    color: ${({ theme: { brandColor }}) => brandColor};
    span {
        margin-left: 6px;
    }
`

const ExpandyBoi = styled.div`
    cursor: pointer;
    color: ${({ theme: { brandColor }}) => brandColor};
    padding: 4px 7px;
    border-radius: 4px;
    background: ${({ theme: { brandColor }}) => transparentize(0.95)(brandColor)};
    border: 1px solid ${({ theme: { brandColor }}) => brandColor};
`

const NavBar = ({ isExpanded, setIsExpanded }) => (
    <Container>
        <NavGrid>
            <Branding>
                <FontAwesomeIcon icon={faHouseUser} />
                <span>WrongMove</span>
            </Branding>
            {setIsExpanded && <ExpandyBoi onClick={() => setIsExpanded(!isExpanded)}>
                <FontAwesomeIcon
                    icon={isExpanded ? faCaretUp : faCaretDown}
                />
            </ExpandyBoi>}
        </NavGrid>
    </Container>
)

export default NavBar