import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const useFilters = () => {
    const location = useLocation()
    const history = useNavigate()
    const histLoc = useLocation()

    const filters = {
        page: 1,
        numberOfPropertiesPerPage: 25,
        includeUnavailableProperties: false,
        sortBy: 'distance',
        radius: 0.25,
        furnishTypes: '',
        propertyTypes: '',
        channel: 'rent',
        ...(Object.fromEntries(new URLSearchParams(location.search)))
    }

    const setFilters = useCallback((filters) => {
        const qs = new URLSearchParams(filters);
        history(histLoc.pathname + '?' + qs.toString())
    }, [history, histLoc])
    
    return {
        filters,
        setFilters,
    }
}

export default useFilters